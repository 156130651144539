// src/../atoms/project.atom.ts

import { atom, selector } from 'recoil';
import { calculateItemTotal } from '../utils/misc';
import {
  fetchProjects,
  createProject,
  fetchProject,
  updateProject,
  deleteProject,
} from '../utils/api';
import { LOEItemI, Milestone, Project } from '../models/general';
import { startTransition } from 'react';
import { addDays, isAfter, isBefore, isToday } from 'date-fns';

export const activeProjectState = atom({
  key: 'activeProjectState',
  default: {} as Project,
});

export const lOEItemListState = atom({
  key: 'LOEList',
  default: [] as LOEItemI[],
});

export const lOETotals = selector({
  key: 'LOETotals',
  get: ({ get }) => {
    const { loeEstimate: lOEItemList, disciplines } = get(activeProjectState);
    if (lOEItemList === undefined) return;
    let total = 0;
    let vals = {
      architecture: 0,
      civil: 0,
      electrical: 0,
      foundation: 0,
      mechanical: 0,
      plumbing: 0,
      structural: 0,
      total: 0,
    };
    disciplines.map((discipline) => {
      let counter = 0;
      lOEItemList.forEach((item) => {
        if (item.discipline === discipline)
          counter = counter + calculateItemTotal(item);
      });
      (vals as any)[discipline] = counter;
      total = total + counter;
    });
    vals.total = total;

    return vals;
  },
});

export const projectListState = atom({
  key: 'projectListState',
  default: [] as Project[],
});

// Persisting project changes
export const projectCRUDState = selector({
  key: 'projectCRUDState',
  get: ({ get }) => get(projectListState),
  set: ({ set, get }, newValue) => {
    const currentProjects = get(projectListState);

    if (newValue.action === 'create') {
      createProject(newValue.data).then((response) => {
        set(projectListState, [...currentProjects, response.data]);
      });
    } else if (newValue.action === 'update') {
      updateProject(newValue.id, newValue.data).then((response) => {
        set(
          projectListState,
          currentProjects.map((project: Project) =>
            project._id === newValue.id ? response.data : project,
          ),
        );
      });
    } else if (newValue.action === 'delete') {
      deleteProject(newValue.id).then(() => {
        set(
          projectListState,
          currentProjects.filter(
            (project: Project) => project._id !== newValue.id,
          ),
        );
      });
    }
  },
});
