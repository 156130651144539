// src/components/TeamManagement.tsx

import React, { useState, useEffect } from 'react';
import { User, Project } from '../models/general';
import { fetchUsers, fetchUsersByCompany } from '../utils/api';

interface TeamManagementProps {
  project: Project;
  companyId: string;
  updateProject: (project: Project) => void;
}

const TeamManagement: React.FC<TeamManagementProps> = ({
  project,
  companyId,
  updateProject,
}) => {
  const [lead, setLead] = useState<User | undefined>(project.lead);
  const [contacts, setContacts] = useState<User[]>(project.contacts || []);
  const [internalUsers, setInternalUsers] = useState<User[]>([]);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  // Filter users based on the company of the active project

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const companyResponse = await fetchUsersByCompany(companyId);
        const clientResponse = await fetchUsersByCompany(
          project.client._id || '',
        );
        console.log('users', clientResponse.data);
        setInternalUsers(companyResponse.data);
        setAvailableUsers([...companyResponse.data, ...clientResponse.data]);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [companyId]);

  const handleLeadChange = (userId: string) => {
    const selectedUser = availableUsers.find((user) => user._id === userId);
    if (selectedUser) {
      setLead(selectedUser);
      updateProject({ ...project, lead: selectedUser });
    }
  };

  const handleAddContact = (userId: string) => {
    const selectedUser = availableUsers.find((user) => user._id === userId);
    if (selectedUser && !contacts.some((contact) => contact._id === userId)) {
      const updatedContacts = [...contacts, selectedUser];
      setContacts(updatedContacts);
      updateProject({ ...project, contacts: updatedContacts });
    }
  };

  const handleRemoveContact = (userId: string) => {
    const updatedContacts = contacts.filter(
      (contact) => contact._id !== userId,
    );
    setContacts(updatedContacts);
    updateProject({ ...project, contacts: updatedContacts });
  };

  return (
    <div>
      <div>
        <label className="block text-lg font-semibold mb-2">
          Project Lead:
        </label>
        <select
          value={lead?._id}
          onChange={(e) => handleLeadChange(e.target.value)}
        >
          <option value="">Select a Lead</option>
          {internalUsers.map((user) => (
            <option key={user._id} value={user._id}>
              {user.firstName} {user.lastName}
            </option>
          ))}
        </select>
      </div>
      <br />
      <div>
        <label className="block text-lg font-semibold mb-2">Team:</label>
        <ul>
          {contacts.map((contact) => (
            <li key={contact._id} className="flex justify-between items-center">
              {contact.firstName} {contact.lastName} - {contact.company?.name}
              <button
                className="bg-red-500 text-white ml-1 px-2 py-1 rounded text-xs"
                onClick={() => handleRemoveContact(contact._id)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>
        <select
          onChange={(e) => {
            handleAddContact(e.target.value);
            e.target.value = '';
          }}
        >
          <option value="">Add a Contact</option>
          {availableUsers.map((user) => (
            <option key={user._id} value={user._id}>
              {user.firstName} {user.lastName} - {user.company?.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default TeamManagement;
